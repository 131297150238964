export const paymentSuccessUrl = "/landingsside/betalingenErGjennomfort";
export const paymentFailedUrl = "/landingsside/betalingenFeilet";

export const PATHS = [
  "/",
  "/invitation",
  "/dashboard",
  "/exam/:courseAssignmentId",
  "/course/:courseAssignmentId",
  "/course/:courseAssignmentId/browse",
  "/course/:courseAssignmentId/browse/test",
  "/course/:courseAssignmentId/browse/module/:moduleSlug",
  "/course/:courseAssignmentId/browse/module/:moduleSlug/test",
  "/course/:courseAssignmentId/browse/module/:moduleSlug/chapter/:chapterSlug",
  "/manage",
  "/manage/courses",
  "/manage/courses/:courseSlug",
  "/manage/courses/:courseSlug/activity",
  "/manage/courses/:courseSlug/info",
  "/manage/members",
  "/manage/members/groups",
  "/manage/users/:userId",
  "/manage/groups/:groupId",
  "/manage/company",
  "/dossier/:courseSlug",
  "/reports",
  "/noMembership",
  "/myProfile",
  "/preview/task/:id",
  "/informasjonskapsler",
  "/bruks-og-kjopsvilkar",
  "/personvern",

  // landing
  "/landingsside",
  "/landingsside/kontakt",
  "/landingsside/kurspakker",
  "/landingsside/kurspakker/:packageSlug",
  "/landingsside/kurs",
  "/landingsside/kurs/:courseSlug",
  "/landingsside/kurs/:courseSlug/kjop",
  "/landingsside/kurspakker/:packageSlug/kjop",

  // payment
  paymentSuccessUrl,
  paymentFailedUrl,
] as const;
